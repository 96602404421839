<template>
    <div class="mypage_auction_exhibit_wrap">
        <table class="my_history_table" width="100%" border="0" cellspacing="0" cellpadding="0"
               style="table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="94px"/>
                <col width="180px"/>
                <col width="280px"/>
                <col width="145px"/>
                <col width="132px"/>
                <col width="190px"/>
                <col width="175px"/>
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{ $t('image') }}</th>
                <th colspan="1">{{ $t('subject') }}</th>
                <th colspan="1">{{ $t('seller') }}</th>
                <th colspan="1">{{ $t('price_s_bid') }}</th>
                <th colspan="1">{{ $t('bid_date') }}</th>
                <th colspan="1">{{ $t('status') }}</th>
            </tr>
            <tr v-for="(data,index) in itemData" :key="`real${index}`">
                <td>{{ tableIdx(index) }}</td>  <!-- No -->
                <td><img :src="returnThumbnail(data)" width="78" height="49"/></td>
                <td class="t_subject">{{ data.i_name }}</td>
                <td v-if="checkData(m_curator)">{{ data.m_curator_nick }}</td>
                <td v-else>{{ data.m_nick }}</td>
                <td>{{ returnPrice(data.i_price) }}</td>
                <td>{{ data.m_bid_date }}</td>
                <td v-if="data.m_status === 3">
                    <button @click="acquisition(data)">
                        {{ $t('acquisition') }}
                    </button>
                </td>
                <td v-else-if="data.m_status===4">{{ $t('a_completed') }}</td>
                <td v-else>-</td>
            </tr>
        </table>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemListMyItemHistoryLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else {
                this.$router.push(`/item/${data.i_sku}`);
            }
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'png')}`;
            }
        },
        checkData(data) {
            return !util.isEmpty(data);
        },
        acquisition(data) {
            EventBus.$emit('acquisition', data.i_idx, data.m_type, data.m_idx, data.i_name);
        },
        returnPrice(price){
            return util.Number.numFormat(price);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
